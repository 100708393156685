<template>
	<div class="event-info">
		<div>
			<span class="event-info__time">{{ session.start | utcTimeFormat }} - {{ session.end | utcTimeFormat }}</span
			><span class="event-info__type">{{ session.typeCommunication }}</span>
		</div>
		<div class="event-info__title"><h2>{{ session.title }}</h2></div>
		<div class="event-info__participants">
			<div class="event-info__participants__label">Chairs:</div>
			<div class="event-info__participants__content">
				<div v-for="p in session.participants.items" v-if="session.participants && session.participants.items" class="event-info__participants__item">
					<div class="event-avatar">
						<img v-if="p.photo && p.photo.uri" :src="p.photo.uri" class="event-avatar__image" />
						<div v-else class="event-avatar__text">{{ p.firstName[0] }}{{ p.lastName[0] }}</div>
					</div>
					<p class="event-info__participants__name">{{ p.firstName[0] }}. {{ p.lastName }}</p>
				</div>
			</div>
		</div>
		<div v-if="session.childEvents && session.childEvents.items" class="event-info__subevents">
			<div v-for="ce in session.childEvents.items" class="event-info__subevents__item">
				<span>{{ ce.start | utcTimeFormat }}</span>
				<div class="event-info__subevents__avatars">
					<div v-for="cp in ce.participants.items" v-if="ce.participants && ce.participants.items" class="avatar">
						<div class="event-avatar">
							<img v-if="cp.photo && cp.photo.uri" :src="cp.photo.uri" class="event-avatar__image" />
							<div v-else class="event-avatar__text">{{ cp.firstName[0] }}{{ cp.lastName[0] }}</div>
						</div>
					</div
					></span>
				</div>
				<div class="event-info__subevents__info">
					<h3>{{ ce.title }}</h3>
					<div v-if="ce.participants && ce.participants.items">
						<span v-for="cp in ce.participants.items">{{ cp.firstName[0] }}. {{ cp.lastName }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'EventInfo',
		props: {
			session: Object,
		},
	};
</script>

<style scoped></style>
