<template>
	<div class="page-live">
		<div v-if="track" class="live-track">
			<!--			<div class="live-track__tracks-wrapper">
				<div class="live-track__tracks container-full" :class="{ 'tablet-content-left': allTracks.length > 6 }">
					<router-link
						v-for="tr in allTracks"
						:key="tr.id"
						:to="{ name: 'LiveTrack', params: { url: tr.url } }"
						class="track"
						:class="{ 'is-active': tr.id === track.id }"
						:title="tr.title"
					>
						<i>{{ tr.order }}</i>
						<strong class="track__title">{{ tr.title }}</strong>
					</router-link>
				</div>
			</div>-->
			<div class="live-track__content container-full">
				<div class="live-track__main">
					<div class="live-track__video">
						<div v-if="playBlock" class="video-restricted">
							<span>This session has restricted access and is only available to users with a full digital registration</span>
						</div>
						<div v-show="track.video_playlist" id="player" class="is-relative">
							<div v-if="!loaded" class="loader-wrapper is-active">
								<div class="loader is-loading"></div>
							</div>
						</div>
						<div v-if="!track.video_playlist && track.poster_picture" class="video-background">
							<img :src="track.poster_picture | imageUrl" />
						</div>
					</div>
					<div v-if="track.translation_iframe_url" class="live-track__translation-wrapper">
						<div class="live-track__translation" :class="{ 'is-active': translationOpen, 'has-disclaimer': wordlyDisclaimer }">
							<div class="live-track__translation__toggle" :class="{ 'is-active': translationOpen }" @click="translationOpen = !translationOpen">
								<div>
									<inline-svg :src="require('../assets/translation.svg')" width="26"></inline-svg>
									<span>Translation</span>
								</div>
								<div class="pointer">
									<span v-if="!translationOpen">Expand</span>
									<span v-if="translationOpen">Collapse</span>
									<inline-svg :src="require('../assets/expand.svg')" width="20"></inline-svg>
								</div>
							</div>
							<div v-if="translationOpen" class="live-track__translation__content" :class="{ 'has-disclaimer': wordlyDisclaimer }">
								<div v-if="wordlyDisclaimer" class="live-track__translation__disclaimer">
									<p>
										EAACI does not guarantee the accuracy of the translations offered during the FAAM-EUROBAT Digital 2022. Some sessions may not be accurately translated due to
										the limitations of the translation software. EAACI accepts no responsibility and disclaims any and all liability for damages that may occur due to incorrect
										translations rendered by any third-party services.
									</p>
									<p>
										We would like to remind our delegates that the official language used during FAAM-EUROBAT Digital 2022 is English. Translations are provided as a convenience
										only, and are still in a pilot testing stage. Any discrepancies or differences created in translations are not binding and have no legal effect for compliance
										or enforcement purposes.
									</p>
									<p>We apologise in advance if any translation glitches or mistakes are encountered during your congress experience.</p>
									<p class="has-text-centered">
										<button class="button is-primary" @click.prevent="wordlyAgree">Ok, I understand</button>
									</p>
								</div>
								<iframe :src="`${track.translation_iframe_url}?fgcolor=25258E&bgcolor=FFFFFF`" />
							</div>
						</div>
					</div>
					<div class="live-track__channels">
						<h3>{{ track.title }}</h3>
						<TrackUpcoming :current-track="track" @fees="onFeeHit" @evaluate="onEvaluate" />
					</div>
				</div>
				<div class="live-track__aside">
					<div v-if="fakeTempBannerVisible" class="live-track__aside-banner">
						<BoothBanner
							:data="{
								content_modal: {
									type: 'pdf_local',
									file: {
										provider: 'azure-storage',
										url: 'https://eaaci-cdn-annual2022-media.azureedge.net/media-library-uploads/EAACI_2022_Berinert_all_formulations_combined_SPC_d87f0fa413.pdf',
									},
								},
								image: {
									provider: 'azure-storage',
									url: 'https://eaaci-cdn-annual2022-media.azureedge.net/media-library-uploads/BERI_2113_CSLB_EAACI_2022_Banner_button_610x135px_V1_0_1_a6dc488c3b.jpg',
								},
							}"
							:analytics="{
								event: 'gaEvent',
								eCategory: 'No Booth Event',
								eAction: 'Live Track Banner',
								eLabel: 'CSL Behring',
								Exhibitor: 'CSL Behring',
								Booth: undefined,
							}"
						/>
					</div>
					<div class="live-track__info">
						<div class="live-track__info__tabs">
							<a :class="{ 'is-active': infoTab === 'info' }" @click.prevent="infoTab = 'info'">
								<inline-svg :src="require('../assets/information.svg')" width="26"></inline-svg>
								<span>Info<span class="is-hidden-touch">rmation</span></span>
							</a>
							<a :class="{ 'is-active': infoTab === 'chat' }" @click.prevent="infoTab = 'chat'">
								<inline-svg :src="require('../assets/topic.svg')" width="26"></inline-svg>
								<span>Chat</span>
							</a>
							<a :class="{ 'is-active': infoTab === 'evaluation' }" @click.prevent="infoTab = 'evaluation'">
								<inline-svg :src="require('../assets/evaluation.svg')" width="26"></inline-svg>
								<span>Evaluation</span>
							</a>
						</div>
						<div class="live-track__info__content">
							<EventInfo v-if="infoTab === 'info' && currentSession" :session="currentSession" />
							<div v-show="infoTab === 'chat' && currentSessionId && sessionHasChat" ref="chatWrapper" class="live-track__chat">
								<PFChat
									v-show="currentSessionId"
									:p-parent-guid="currentSessionId"
									p-channel-name="faam2022"
									:p-person="currentUser"
									:p-moderation-on="sessionModerated"
									:p-display-avatar="true"
									:p-avatar-url="currentUserImage"
									@hover:author="hoverAuthor"
									@leave:author="leaveAuthor"
								/>
								<div v-if="userInfo && userInfo.firstName" class="live-track__user-box" :style="userInfoStyle" @mouseenter="userBoxEnter" @mouseleave="userBoxLeave">
									<div class="live-track__user-box-main">
										<router-link class="image" :to="{ name: 'AttendeeDetail', params: { id: userInfo.id } }">
											<img v-if="hasFrame(userInfo)" :src="platformSettings.profileFrameImage | imageUrl" class="frame" />
											<inline-svg v-if="!userInfo.image && userInfo.gender === 'Male'" :src="require('../assets/user_male.svg')" width="25" />
											<inline-svg v-else-if="!userInfo.image && userInfo.gender === 'Female'" :src="require('../assets/user_female.svg')" width="25" />
											<inline-svg v-else-if="!userInfo.image" :src="require('../assets/user_generic.svg')" width="25" />
											<img v-else :src="userInfo.image | imageUrl('thumbnail')" />
										</router-link>
										<router-link class="user-box-name" :to="{ name: 'AttendeeDetail', params: { id: userInfo.id } }">
											<strong>{{ userInfo.firstName }} {{ userInfo.lastName }}</strong>
											<span v-if="userInfo.job_title || userInfo.company"
												>{{ userInfo.job_title }} <span v-if="userInfo.job_title && userInfo.company">at</span> {{ userInfo.company }}</span
											>
										</router-link>
									</div>
									<div v-if="!isCurrentUser(userInfo)" class="live-track__user-box-actions">
										<a v-if="!isFriend(userInfo) && !userInfo.incoming_req && !userInfo.outgoing_req" class="button is-primary" @click="sendFriendRequest(userInfo)">
											<inline-svg :src="require('../assets/user-add.svg')" width="25"></inline-svg>
											Connect
										</a>
										<span v-if="isFriend(userInfo)" class="button is-primary">
											<inline-svg :src="require('../assets/user-check.svg')" width="25"></inline-svg>
											Connected
										</span>
										<span v-if="userInfo.incoming_req" class="button is-primary">
											<span>Sent</span>
										</span>
										<router-link :to="{ name: 'Chat', query: { user: userInfo.id }, params: { user: userInfo.id } }" class="button is-primary" target="_blank">
											<inline-svg :src="require('../assets/topic.svg')" width="20"></inline-svg>
											Chat
										</router-link>
									</div>
								</div>
							</div>
							<div v-show="infoTab === 'vote' && currentSessionId && sessionHasVote" :class="{ 'live-track__vote': voteActive, 'live-track__none': !voteActive }">
								<PFPollRemote :p-user="currentUser" p-channel-name="faam2022" :p-session-guid="currentSessionId" @show="showPoll" @close="closePoll"></PFPollRemote>
								<span v-if="voteActive === false">No voting is currently available</span>
							</div>
							<div v-if="infoTab === 'evaluation' && currentSessionId && sessionHasEvaluation">
								<iframe v-if="evaluationUrl" :src="evaluationUrl"></iframe>
							</div>
							<div v-if="infoTab === 'info' && !currentSession" class="live-track__none">
								<span>No sessions are currently available</span>
							</div>
							<div v-if="infoTab === 'chat' && !currentSessionId" class="live-track__none">
								<span>No sessions are currently available</span>
							</div>
							<div v-if="infoTab === 'chat' && currentSessionId && !sessionHasChat" class="live-track__none">
								<span>The chat feature is at-present inactive for this session</span>
							</div>
							<div v-if="infoTab === 'vote' && !currentSessionId" class="live-track__none">
								<span>No sessions are currently available</span>
							</div>
							<div v-if="infoTab === 'evaluation' && !currentSessionId" class="live-track__none">
								<span>No sessions are currently available</span>
							</div>
							<div v-if="infoTab === 'vote' && currentSessionId && !sessionHasVote" class="live-track__none">
								<span>The voting feature is at-present inactive for this session</span>
							</div>
							<div v-if="infoTab === 'evaluation' && currentSessionId && !sessionHasEvaluation" class="live-track__none">
								<span>The evaluation feature is at-present inactive for this session</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import session from '../utils/session';
	import axios from 'axios';
	import Vue from 'vue';
	import LoginModal from '../components/LoginModal';
	import { PFChat, PFPollRemote } from '@congress/platform-components-eaaci';

	import EventInfo from '../components/EventInfo';
	import { CYIM_BEARER, ANALYTICS_URL } from '../consts';
	import TrackUpcoming from '../components/TrackUpcoming';
	import meta from '../utils/meta';
	import platformSettings from '../utils/platformSettings';
	import moment from 'moment-timezone';
	import BoothBanner from '@/components/Booth/BoothBanner';

	const MODERATED = [
		'ff45f0c3-c71d-4b54-b300-32f7e550267b',
		'37adb6fb-0ecb-467a-b65b-72463a38838d',
		'a4ee6728-7b0f-45d9-b64a-48a1c4d44658',
		'abaef32f-5673-40c0-ac6d-12438709438f',
		'e380fe70-d62c-4728-a116-968a7e4282fa',
		'dd35f643-e67b-4921-9abb-78731131ad88',
		'3c204e5b-9ec8-49e4-b68e-aafccc0b3d2e',
		'ee01a312-3e35-48f8-9542-043d83944faa',
		'8350c4e7-2bc4-40d5-a65f-e09271d58b4b',
		'f5b4299a-06a8-4c8a-9eb7-12756d4f2f7b',
		'55396e8b-57c8-417f-bef7-32a1556d7f0b',
		'd5ec2359-9307-4cc9-a635-0dbea2efd267',
		'22703b3e-01c6-47ff-8b55-fabc717185f1',
		'6f44cfa3-b8b7-430c-89cf-bcdf25223cc6',
		'cd851916-26ca-46d1-8f2a-0ca607c10792',
		'760dabb5-3ee7-4738-88dd-dfb25e31af76',
		'06106992-60ca-4f14-824c-d67565722870',
		'49b37f2d-b855-41c4-a93a-fb39f378b59f',
		'dde1859d-b6af-4e9b-82f0-b4d55600b0eb',
		'34279daa-4ea4-45de-8473-6718f54fcab6',
		'1b8c9c98-940e-447d-a19d-71b07a0f9665',
		'9baf105b-6203-4e9c-9810-53430a683194',
		'7aa71700-d0ab-4738-830f-2e77ac4cbcc9',
		'466e6aa5-2536-43a1-9db8-715f44f82f10',
		'153e4b15-ff31-4d32-8880-7180da8815ca',
		'346688a5-49ba-4722-9664-be370259231a',
		'1cbac031-0c76-4fb8-bf26-d9877f985d4a',
		'e8b8f6d2-794a-49ac-bf00-42f6d9458302',
		'52a1e944-6d67-40ac-b770-7ba2cbfb2736',
		'e1579f3e-a041-4466-aec1-47d2b80af647',
		'59695fb1-3f1e-4043-892d-6f51f761a1d1',
		'01b3d89e-1153-4a44-9b6b-0fc30ec8f0b2',
		'770ee3c7-8f43-4cbb-8e10-be6cf9016568',
		'ef2d9b00-cfce-4ffa-a246-466ff09484e2',
		'c9c789ea-fc18-4e56-afae-132c28ba1c58',
		'4d558fbc-5f8a-4785-86cc-cb90d13c49d5',
		'e5446bd2-f9a5-4e17-af5e-93db6ad23658',
		'ab6c1625-9e35-488d-b9c5-e0a8b9c4b5c1',
		'30f1d597-c8db-4bcd-a57d-5a5078ac0a37',
		'031a4651-6ea9-4669-9170-469a2024313b',
		'078087f3-fd73-4f71-a4a9-a1c349b9bf60',
		'508cd92a-6741-4202-88f0-a9172f85fb75',
		'6391e2f0-0305-46ad-9103-1ed3086775d2',
		'40e6694f-ddd2-47d5-a564-79b2f6de6e3f',
		'b81d1b61-e62a-40bf-b6ff-09ea24690787',
		'f907f0fd-06a1-45b1-8d23-d1ce50e5a042',
		'2f85e9df-a698-4023-8e4c-de12f3a7df3a',
		'226c11be-3a35-497b-97fc-1ce4fc5fe0a5',
		'524386e0-47d4-47f6-aad0-5498805032dc',
		'63da868e-77b8-4111-a385-e27c6954b599',
		'4d28d21c-1a89-4eb8-8a7f-8f2c930efc09',
		'cb323d06-b7a5-4ba3-bbd6-4eb81e46ce69',
		'4a322d85-4596-41d1-b555-1c787f7fc62a',
		'502ec15d-52bb-417f-bd79-04ecb22432b0',
		'6b23e0da-d054-49d9-9571-efb553fdd2c7',
		'48cdcab5-bb30-48c7-9f61-fb1e270c672c',
		'e5446bd2-f9a5-4e17-af5e-93db6ad23658',
		'749f1186-41b8-4c02-96b1-1fe91bd2d591',
	];

	const debounce = (fn, delay) => {
		var timeoutID = null;
		return function() {
			clearTimeout(timeoutID);
			var args = arguments;
			var that = this;
			timeoutID = setTimeout(function() {
				fn.apply(that, args);
			}, delay);
		};
	};

	var throttle = (func, ms = 50, context = window) => {
		let to;
		let wait = false;
		return (...args) => {
			let later = () => {
				func.apply(context, args);
			};
			if (!wait) {
				later();
				wait = true;
				to = setTimeout(() => {
					wait = false;
				}, ms);
			}
		};
	};

	const throttled = throttle(func => {
		func();
	}, 30000);

	const axiosWithoutAuth = axios.create({
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${CYIM_BEARER}`,
		},
	});

	const axiosAnalytics = axios.create({
		baseURL: ANALYTICS_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${session.state.key}`,
		},
	});

	export default {
		name: 'LiveTrack',
		components: { BoothBanner, TrackUpcoming, EventInfo, PFChat, PFPollRemote },
		data() {
			return {
				allTracks: [],
				track: {},
				userToken: null,
				liveNow: undefined,
				logInit: false,
				interval: null,
				intervalTimeout: 60000,
				refreshInterval: null,
				refresh: 0,
				currentSession: false,
				infoTab: 'info',
				session: session.state,
				player: null,
				loaded: false,
				voteActive: false,
				showVote: true,
				translationOpen: false,
				wordlyDisclaimer: true,
				playBlock: false,
				platformSettings: platformSettings.state,
				userInfo: {},
				userInfoStyle: {},
				userInfoShowTimeout: null,
				userInfoHideTimeout: null,
				userInfoCurrentId: null,
				fakeTempBannerVisible: false,
			};
		},
		beforeRouteEnter(to, from, next) {
			$http.get(`/live-tracks/${to.params.url}`).then(
				data => {
					next(vm => vm.setData(data));
				},
				err => {
					if (err.response.status === 403) {
						window.sessionStorage.setItem('afterLogin', JSON.stringify({ name: to.name, params: to.params }));
						Vue.prototype.$modal.show(
							LoginModal,
							{},
							{
								classes: 'login-modal',
								width: '90%',
								maxWidth: 550,
								height: 'auto',
								adaptive: true,
							},
							{
								'before-close': () => {
									next(false);
									window.sessionStorage.removeItem('afterLogin');
									this.$Progress.finish();
								},
							}
						);
					}
				}
			);
		},
		beforeRouteUpdate(to, from, next) {
			if (window.wowza) {
				window.sessionStorage.setItem('forceReload', JSON.stringify({ name: to.name, params: to.params }));
				location.reload();
			} else {
				$http.get(`/live-tracks/${to.params.url}`).then(data => {
					this.setData(data);
					next();
				});
			}
		},
		beforeRouteLeave(to, from, next) {
			if (window.wowza) {
				window.sessionStorage.setItem('forceReload', JSON.stringify({ name: to.name, params: to.params }));
				location.reload();
			} else {
				next();
			}
		},
		computed: {
			currentSessionId() {
				if (!this.liveNow) return false;
				if (this.liveNow.length > 0) {
					if (this.liveNow[0].session && this.liveNow[0].session.id) {
						return this.liveNow[0].session.id;
					}
				}
				return false;
			},
			currentUser() {
				if (!this.session.user.id || !this.session.user.firstName || !this.session.user.lastName) return false;
				return { id: `${this.session.user.id}`, name: `${this.session.user.firstName} ${this.session.user.lastName}` };
			},
			currentUserImage() {
				if (!this.session.user.id) return '';
				if (!this.session.user.image) return '';
				return this.$options.filters.imageUrl(this.session.user.image);
			},
			sessionHasChat() {
				if (!this.currentSession) return false;
				return this.currentSession.hasQna === true;
			},
			sessionHasVote() {
				if (!this.currentSession) return false;
				return this.currentSession.hasVoting === true;
			},
			sessionHasEvaluation() {
				if (!this.currentSession) return false;
				return !!this.currentSession.evaluationUrl;
			},
			evaluationUrl() {
				if (!this.currentSession) return false;
				return this.currentSession.evaluationUrl;
			},
			sessionModerated() {
				if (!this.currentSession) return false;
				if (!this.currentSessionId) return false;
				if (this.currentSessionId) {
					if (MODERATED.indexOf(this.currentSessionId.toLowerCase()) !== -1) return true;
				}
				return false;
			},
		},
		mounted() {
			$http.get('/live-tracks').then(data => {
				this.allTracks = data.data.sort((a, b) => a.order - b.order);
			});

			this.interval = setInterval(() => {
				this.getCurrentSession();
			}, this.intervalTimeout);

			this.refreshInterval = window.setInterval(() => {
				this.isTempFakeBannerVisible();
			}, 30 * 1000);
			this.isTempFakeBannerVisible();

			let disclaimer = window.localStorage.getItem('wordlyDisclaimer');
			if (disclaimer && disclaimer === 'true') {
				this.wordlyDisclaimer = false;
			}
		},
		beforeDestroy() {
			clearInterval(this.interval);
			if (this.refreshInterval) {
				window.clearInterval(this.refreshInterval);
			}
		},
		methods: {
			setData(data) {
				this.track = data.data;
				meta.setTitle(this.track.title);
				let wowza = document.createElement('script');
				wowza.src = '//cdn.jsdelivr.net/npm/@clappr/player@latest/dist/clappr.min.js';
				wowza.onload = () => {
					this.loaded = true;
					if (window.Clappr) {
						this.player = new window.Clappr.Player({
							width: '100%',
							height: '100%',
							disableErrorScreen: true, // Disable the internal error screen plugin
							source: this.track.video_playlist,
							parentId: '#player',
							poster: this.track.poster_picture
								? this.$options.filters.imageUrl(this.track.poster_picture)
								: 'https://www.eaaci.org/images/buttons/Website_banner_SAM_-_1920x410_px.png',
							events: {
								onTimeUpdate: () => {
									throttled(this.logPlay);
								},
							},
						});
					}
				};
				document.body.appendChild(wowza);
				window.wowza = true;
				this.userToken = session.generateToken();

				this.getCurrentSession();
			},
			getCurrentSession() {
				if (this.track && this.track.live_now_guid) {
					axiosWithoutAuth
						.post('https://gateway.api.cyim.com/graphql', {
							query:
								'query currentSessionByTrack($locationId: ID!) { location(id: $locationId) { id name broadcasts(filter: { onAir: true}) { items { session: source { ... on Event { id title } } } } }}',
							variables: { locationId: this.track.live_now_guid },
						})
						.then(({ data }) => {
							if (data && data.data && data.data.location && data.data.location.broadcasts && data.data.location.broadcasts.items) {
								this.liveNow = data.data.location.broadcasts.items;
								if (this.currentSessionId) {
									axiosWithoutAuth
										.post('https://gateway.api.cyim.com/graphql', {
											query:
												'query sessionInformation ($sessionId : ID!){ session: event(id: $sessionId) { _id: id color title duration location { id } onAir: ongoing typeCommunication: tag(category: "/session/type") start end participants(role:"chair") { items { _id: id firstName lastName photo { uri: url _id: id } } } childEvents: schedule { items { _id: id title start duration participants(role: "speaker") { items { _id: id firstName lastName photo { uri: url _id: id } } } } } hasVoting: tag(category: "/interactivity/voting") hasQna: tag(category: "/interactivity/qna") evaluationUrl: tag(category: "/session/evaluation") }}',
											variables: { sessionId: this.currentSessionId },
										})
										.then(sessionData => {
											if (sessionData.data && sessionData.data.data && sessionData.data.data.session) {
												this.currentSession = sessionData.data.data.session;
											}
										});
								} else {
									this.currentSession = false;
								}
							}
						});
				}
			},
			logPlay() {
				if (this.logInit === false) {
					this.logInit = true;
				} else {
					axiosAnalytics.post('/analytics', {
						trackId: this.track.id,
						trackTitle: this.track.title,
						actionId: 'watched_30s',
					});
				}
			},
			hoverAuthor(e) {
				if (e && e.event && e.userId) {
					if (e.userId !== this.userInfoCurrentId) {
						if (this.userInfoHideTimeout) window.clearTimeout(this.userInfoHideTimeout);
						this.userInfo = {};
						this.userInfoCurrentId = null;

						$http.get(`/users/network/${e.userId}`).then(
							res => {
								this.$nextTick(() => {
									this.userInfoCurrentId = e.userId;
									this.userInfo = res.data;
								});
							},
							() => {
								this.userInfo = {};
							}
						);
					} else if (e.userId === this.userInfoCurrentId) {
						if (this.userInfoHideTimeout) window.clearTimeout(this.userInfoHideTimeout);
					}

					if (e.event.target) {
						let dim = e.event.target.getBoundingClientRect();
						let wr = this.$refs.chatWrapper.getBoundingClientRect();

						this.userInfoStyle = { top: dim.top - wr.top + 30 + 'px', left: dim.left - wr.left + 30 + 'px' };
					}
				}
			},
			leaveAuthor(e) {
				if (this.userInfoHideTimeout) window.clearTimeout(this.userInfoHideTimeout);
				this.userInfoHideTimeout = window.setTimeout(() => {
					this.userInfo = {};
					this.userInfoCurrentId = null;
				}, 800);
			},
			userBoxEnter(e) {
				this.$nextTick(() => {
					if (this.userInfoHideTimeout) window.clearTimeout(this.userInfoHideTimeout);
				});
			},
			userBoxLeave(e) {
				this.userInfoHideTimeout = window.setTimeout(() => {
					this.userInfo = {};
					this.userInfoCurrentId = null;
				}, 200);
			},
			showPoll(data) {
				this.infoTab = 'vote';
				this.voteActive = true;
			},
			closePoll() {
				this.voteActive = false;
			},
			wordlyAgree() {
				window.localStorage.setItem('wordlyDisclaimer', 'true');
				this.wordlyDisclaimer = false;
			},
			onFeeHit(status) {
				this.playBlock = status;
				if (status === true) {
					this.player.stop();
				}
			},
			onEvaluate() {
				if (this.sessionHasEvaluation) {
					this.infoTab = 'evaluation';
				}
			},
			hasFrame(attendee) {
				return (
					attendee.eaaci_membership === true && attendee.profile_frame_enabled === true && this.platformSettings.profileFrameEnabled && this.platformSettings.profileFrameImage
				);
			},
			isCurrentUser(attendee) {
				return attendee.id === this.session.user.id;
			},
			isFriend(attendee) {
				return attendee.email !== null || attendee.friend === true;
			},
			sendFriendRequest(attendee) {
				if (this.session.user.id) {
					$http
						.post('/friend-requests', {
							author: this.session.user.id,
							recipient: attendee.id,
						})
						.then(() => {
							this.$set(attendee, 'incoming_req', true);
						});
				}
			},
			isTempFakeBannerVisible() {
				let target = moment.tz.guess();
				let dStart1 = moment.tz(`2022-07-01 17:15`, 'CET');
				let dEnd1 = moment.tz(`2022-07-01 17:45`, 'CET');
				let dStart2 = moment.tz(`2022-07-02 12:30`, 'CET');
				let dEnd2 = moment.tz(`2022-07-02 13:00`, 'CET');
				let now = moment().tz(target);
				this.fakeTempBannerVisible = this.track.url === 'channel6' && (now.isBetween(dStart1, dEnd1) || now.isBetween(dStart2, dEnd2));
			},
		},
	};
</script>
