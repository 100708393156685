<template>
	<div class="data-capture-modal-content">
		<div class="booth-disclaimer-modal__content content">
			<VueMarkdown v-if="disclaimer" :source="disclaimer" :prerender="prerender" />
		</div>

		<div class="buttons">
			<button class="button is-primary2 is-outlined" @click.prevent="cancel">No, I don't agree</button>
			<button class="button is-primary2" @click.prevent="agree">Yes, I agree</button>
		</div>
	</div>
</template>

<script>
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';

	export default {
		name: 'DataCaptureModal',
		components: { VueMarkdown },
		props: {
			disclaimer: {
				type: String,
				required: true,
			},
		},
		methods: {
			agree() {
				this.$emit('close', true);
			},
			cancel() {
				this.$emit('close', false);
			},
			prerender: prerender,
		},
	};
</script>
